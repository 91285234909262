// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-conversao-jsx": () => import("./../../../src/pages/conversao.jsx" /* webpackChunkName: "component---src-pages-conversao-jsx" */),
  "component---src-pages-cotacao-jsx": () => import("./../../../src/pages/cotacao.jsx" /* webpackChunkName: "component---src-pages-cotacao-jsx" */),
  "component---src-pages-denuncias-jsx": () => import("./../../../src/pages/denuncias.jsx" /* webpackChunkName: "component---src-pages-denuncias-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-ouvidoria-jsx": () => import("./../../../src/pages/ouvidoria.jsx" /* webpackChunkName: "component---src-pages-ouvidoria-jsx" */),
  "component---src-pages-privacidade-jsx": () => import("./../../../src/pages/privacidade.jsx" /* webpackChunkName: "component---src-pages-privacidade-jsx" */),
  "component---src-pages-seguranca-cibernetica-jsx": () => import("./../../../src/pages/seguranca-cibernetica.jsx" /* webpackChunkName: "component---src-pages-seguranca-cibernetica-jsx" */),
  "component---src-pages-somos-hub-jsx": () => import("./../../../src/pages/somos-hub.jsx" /* webpackChunkName: "component---src-pages-somos-hub-jsx" */),
  "component---src-pages-tarifas-jsx": () => import("./../../../src/pages/tarifas.jsx" /* webpackChunkName: "component---src-pages-tarifas-jsx" */)
}

